/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Link } from "gatsby";

const Blog = ({ data, pageContext, location }) => {
  const seo = data.wpPage.seo;
  const { posts } = data.allWpPost;
  // Manually set the robots tag to never index paginated pages of blog.
  seo.metaRobotsNofollow = pageContext.pageNumber === 0 ? "follow" : "nofollow";
  seo.metaRobotsNoindex = pageContext.pageNumber === 0 ? "index" : "noindex";
  return (
    <Layout seo={data.wpPage.seo} location={location}>
      <div>Hello, blog page {pageContext.pageNumber}!</div>
      <ul>
        {posts.map((post, i) => (
          <li key={i}>
            <Link to={post.uri}>{post.title}</Link>
            <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wpPage(isPostsPage: { eq: true }) {
      ...SEO
    }

    allWpPost(
      sort: { fields: dateGmt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      posts: nodes {
        ...PostList
      }
    }
  }

  fragment PostList on WpPost {
    title
    uri
    excerpt
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
  }
`;

export default Blog;
